
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var google: any;
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import notFound from "@/components/notFound/NotFoundComponents.vue";
import MobileSearch from "./MobileSearch.vue";
import { useStore } from "vuex";
import Gtag from "../assets/gtag";
import iconFilter from "../icons/icon-filter.vue";


export default defineComponent({
  name: "SearchFilterComponents",
  components: {
    notFound,
    MobileSearch,
    iconFilter
  },
  setup() {
    const InputDiv = ref();
    const focusInput = ref(false);
    const optionsSearch = { strictBounds: false };
    const Search = async () => {
      const autocomplete = new google.maps.places.Autocomplete(
        InputDiv.value,
        optionsSearch
      );
      autocomplete.addListener("place_changed", async () => {
        let place = autocomplete.getPlace();
        let search;
        for (let key in autocomplete.gm_accessors_.place) {
          if (autocomplete.gm_accessors_.place[key]?.formattedPrediction)
            search = autocomplete.gm_accessors_.place[key]?.formattedPrediction;
        }

        if (place.formatted_address) {
          form.search = search;
          searchButton();
        }
      });
    };

    const store = useStore();
    const fill = computed(() => {
      const filter: string[] = store.state.filters;
      if (filter.includes('sortSobirator')) return '#BEE256'
      if (filter.length == 0) return '#898A8D'
      if (filter.length > 0) return '#323232'
      if (filter.includes('data')) return '#898A8D'
      return '#898A8D'
    });
    const lang = ref(store.state.country);
    const geo = new google.maps.Geocoder();
    const searchButtonClose = () => {
      new Gtag("Clear and close the search", "SearchFilter", "Close");
      store.state.SearchCord = "delete";
      form.search = null;
    };
    const form = reactive({
      search: null,
    });

    const searchButton = () => {
      new Gtag("Searching for places", "SearchFilter", "Open");
      geo.geocode(
        {
          address: form.search,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function (results: any) {
          if (results) {
            let cord = results[0].geometry.location;
            cord = cord.lat() + "," + cord.lng();
            store.state.SearchCord = cord;
          }
        }
      );
    };

    let index = 0;
    const getGeoAddress = () => {
      if (index == 0) {
        geo.geocode(
          {
            address: getGeo.value.toString() || notFound.value.toString(),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (results: any) {
            console.log(results);

            if (results) {
              index++;
              store.state.GeoAddress = results[0].formatted_address;
              console.log(store.state.GeoAddress);
            }
          }
        );
      }
    };
    const notFound = computed(() => {
      return store.state.notFoundGeo;
    });
    watch(notFound, getGeoAddress);
    const getGeo = computed(() => {
      return store.state.GeoCord;
    });
    watch(getGeo, getGeoAddress);
    const filterSearchStatus = ref(false);
    const activeFilter = store.state.filters.length == 0 ? ref(false) : ref(true);

    const setFilter = (productId: string) => {
      store.state.syncStatus = true;
      setTimeout(() => {
        activeFilter.value = true;
        if (productId == "data") {
          activeFilter.value = false;
          store.state.filters.unshift(productId);
          return;
        }
        if (store.state.filters.includes(productId)) {
          for (let index in store.state.filters) {
            if (store.state.filters[index] == productId) {
              store.state.filters.splice(index, 1);
            }
          }
        } else {
          store.state.filters.push(productId);
        }
        if (store.state.filters.length == 0) activeFilter.value = false;
      }, 100);
    };
    const setFilterStatus = (productId: string) => {
      if (store.state.filters.length == 0 && productId == "data") return "disabled";
      let isset = store.state.filters.filter((id: string) => id == productId);
      if (isset.length > 0) {
        return true;
      }
      if (productId == "data" && store.state.filters[0] == null) return true;
      return false;
    };
    const productList = store.state.listFilter

    onMounted(async () => {
      await Search();
    });
    const isMobile = window.innerWidth <= 440 ? true : false;
    return {
      store,
      lang,
      searchButtonClose,
      form,
      searchButton,
      activeFilter,
      filterSearchStatus,
      setFilter,
      setFilterStatus,
      productList,
      InputDiv,
      Gtag,
      focusInput,
      isMobile,
      fill
    };
  },
});
