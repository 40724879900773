
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
    name: 'PreLoaderComponents',
    setup() {
        const store = useStore()
        const lang = ref(store.state.urlAddress)
        const Image = lang.value == 'yandex' ? require('./img/PreloaderRu.gif') : require('./img/PreloaderEn.gif')
        return {
            lang,
            Image,
        }
    },
})
