
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var google: any;

import { defineComponent, ref, onMounted, watch, computed } from "vue";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { useStore } from "vuex";
import User from "../assets/contentBallon";
import Gtag from "../assets/gtag";
export default defineComponent({
  name: 'GoogleMapComponents',
  setup() {
    const DivMap = ref();
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let map: any;

    let pos = {
      lat: Number(store.state.CenterMap.split(",")[0]),
      lng: Number(store.state.CenterMap.split(",")[1]),
    };
    const cord = computed(() => {
      return store.state.SearchCord;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let Search: any;
    const createSearchPoint = () => {
      if (cord.value == "delete") {
        if (Search == undefined) return;
        Search.setMap(null);
        Search.setVisible(false);
        return;
      }
      let cords = {
        lat: Number(cord.value.split(",")[0]),
        lng: Number(cord.value.split(",")[1]),
      };
      if (Search) {
        Search.setMap(null);
        Search.setVisible(false);
      }
      Search = new google.maps.Marker({
        position: cords,
        map: map,
        icon: {
          url: require("@/assets/img/points/icon-search.svg"),
          scaledSize: new google.maps.Size(35, 35),
        },
      });
      map.setCenter(cords);
    };
    watch(cord, createSearchPoint);
    const geoCord = computed(() => {
      return store.state.GeoCord;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let GeoPoint: any;
    let cordsGeo: { lat: number; lng: number };
    const createGeoPoint = () => {
      if (GeoPoint !== undefined) {
        map.setCenter(cordsGeo);
        return;
      }
      cordsGeo = {
        lat: Number(geoCord.value.split(",")[0]),
        lng: Number(geoCord.value.split(",")[1]),
      };

      GeoPoint = new google.maps.Marker({
        position: cordsGeo,
        map: map,
        icon: {
          url: require("@/assets/img/points/I_m_here.svg"),
          scaledSize: new google.maps.Size(35, 35),
        },
      });
      map.setCenter(cordsGeo);
    };
    watch(geoCord, createGeoPoint);

    const Zoom = computed(() => {
      return store.state.Zoom;
    });
    const setZoom = () => {
      map.setZoom(Zoom.value);
    };
    watch(Zoom, setZoom);

    const MapCenter = computed(() => {
      return store.state.CenterMap;
    });

    const setCenter = () => {
      let cord = {
        lat: Number(MapCenter.value.split(",")[0]),
        lng: Number(MapCenter.value.split(",")[1]),
      };
      map.setCenter(cord);
    };
    watch(MapCenter, setCenter);

    const infoWindow = new google.maps.InfoWindow({
      content: "",
    });
    const initMap = () => {
      let opt = {
        center: pos,
        zoom: store.state.Zoom,
        disableDefaultUI: true,
        navigationControl: false,
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        disableDoubleClickZoom: true,
        minZoom: 4,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.country",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.locality",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.province",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.line",
            stylers: [
              {
                color: "#330000",
              },
            ],
          },
          {
            featureType: "transit.station",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.bus",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "geometry.stroke",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.text.fill",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            stylers: [
              {
                saturation: -90,
              },
            ],
          },
        ],
      };
      let mapElement = DivMap.value;

      map = new google.maps.Map(mapElement, opt);
      google.maps.event.addListener(map, "zoom_changed", () => {
        store.state.Zoom = map.zoom;
      });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let sortedAddress: any;
    const address = async (type: string[]) => {
      let get = store.getters.ADDRESSES(type);
      if (get.length == 0) {
        setTimeout(() => {
          address(type);
        }, 500);
      } else {
        sortedAddress = get;
        await createPoints();
        await load();
      }
    };
    const filters = computed(() => {
      return store.state.filters;
    });
    watch(filters.value, async (item) => {
      let filter: string[] = [];
      if (item[0] == "data") {
        store.state.filters.splice(0, store.state.filters.length);
        address(["data"]);
        return;
      }
      await item.forEach((item: string) => filter.push(item));
      if (store.state.filters.length > 0) address(filter);
    });
    google.maps.version = "3.31.9";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let markers: any;
    const createPoints = async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      markers = sortedAddress.map((item: any) => {
        // START sortedAddress
        let label = new User(item.label, item.address, item.sobirator).content;
        let cord = item.coords.split(" ");
        item.lat = cord[0];
        item.lng = cord[1];
        let coordsPosition = {
          lat: Number(item.lat),
          lng: Number(item.lng),
        };
        let image;
        if (item.sobirator == 1 || item.sobirator == "true") {
          image = require("./img/FlonqEco.svg");
        } else {
          image = require("./img/Flonq.svg");
        }
        const marker = new google.maps.Marker({
          position: coordsPosition,
          title: item.address,
          infoMarker: item,
          icon: {
            url: image,
            scaledSize: new google.maps.Size(35, 35),
          },
          // optimized: true
        });
        marker.addListener("click", async () => {
          const zoom = map.zoom;
          if (zoom < 17) {
            map.setZoom(17);
          }
          new Gtag(marker.infoMarker.code, "SearchFilter", "Close");
          map.panTo(coordsPosition);
          store.state.address_id = marker.infoMarker;
          await infoWindow.setContent(label);
          await infoWindow.open(map, marker);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const close = document.querySelector(".gm-ui-hover-effect")!;
          close.addEventListener("click", () => {
            infoWindow.setContent("");
          });
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const btn_route = document.querySelector("div.btn-route")!;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const copyText = document.querySelector(
            "div.content > div.address > img"
          )!;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const route = document.querySelector(
            "div.footer > div.btn-route > img"
          )!;
          route.addEventListener("mouseover", () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.footer > div.btn-route > div")!
              .classList.add("active");
          });
          route.addEventListener("mouseleave", () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.footer > div.btn-route > div")!
              .classList.remove("active");
          });
          copyText.addEventListener("mouseover", () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.content > div.address > div.help")!
              .classList.add("active");
          });
          copyText.addEventListener("mouseleave", () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.content > div.address > div.help")!
              .classList.remove("active");
          });
          copyText.addEventListener("click", () => {
            CopyTextMarker(marker.infoMarker.address);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.content > div.address > div.help")!
              .classList.remove("active");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document
              .querySelector("div.content > div.address > div.copyText")!
              .classList.add("active");
            const timeClose = () => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              document
                .querySelector("div.content > div.address > div.copyText")!
                .classList.remove("active");
            };
            setTimeout(() => {
              timeClose();
            }, 1000);
          });
          btn_route.addEventListener("click", () => {
            setLink(marker.infoMarker.coords);
          });
        });
        return marker;
        // END sortedAddress
      });
      setTimeout(() => {
        store.state.syncStatus = false
      }, 1000);
    };
    const setLink = (cord: string) => {
      let baseCord = cord.split(" ");
      let geoCoords = baseCord[0] + "," + baseCord[1];

      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        let link = `maps://?q=' + ${cord}`;
        window.open(link, "_system");
      } else if (
        /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.href = `geo:${geoCoords}?daddr=${geoCoords}`;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let open: any = window.open(
          `https://www.google.com/maps/search/?api=1&query=${cord}`,
          "_blank"
        );
        open.focus();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calc = (markers: any) => {
      for (var i = 0; i < markers.length; i++) {
        if (
          markers[i].infoMarker.sobirator == "true" ||
          markers[i].infoMarker.sobirator == "1"
        ) {
          return { text: markers.length, index: 2, title: "Sobirator" };
        }
      }
      return { text: markers.length, index: 1, title: "Normal" };
    };
    const CopyTextMarker = (text: string) => {
      navigator.clipboard.writeText(text);
    };
    const mcOptions = {
      styles: [
        {
          textColor: "white",
          url: require("./img/Group-normal.svg"),
          height: 67,
          width: 67,
        },
        {
          textColor: "white",
          url: require("./img/Group-eco.svg"),
          height: 67,
          width: 67,
        },
      ],
      gridSize: 67,
      maxZoom: 15,
    };
    const load = () => {
      if (markers == undefined) load;
      const markersC = new MarkerClusterer(map, markers, mcOptions);

      markersC.setCalculator(calc);
    };

    onMounted(async () => {
      await initMap();
      address(["data"]);
    });
    return {
      DivMap,
      store,
    };
  },
});
