
import { defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const globalH = window.innerHeight + "px";

    document.documentElement.style.setProperty("--globalH", globalH);

    const store = useStore();
    const country = store.state.lang;
    store.dispatch("getAddresses", country);
  },
});
