
import { defineComponent, ref } from "vue";
import store from "./store";
export default defineComponent({
  name: 'HeaderComponents',
  setup() {
    //Переменные на возвращение в темлате
    const Menu = ref();
    const CurrentСountry = ref();
    const url = ref<URL>(new URL(window.location.href));
    const backURL = url.value.href.split('store.').join('')
    const a = ref(url.value.hostname.split('.'))
    a.value.shift()
    console.log(a);

    // __________________________________

    // Получение не реактивных данных из store


    const UseCountry = store.state.country;

    const lang = ref(store.state.urlAddress);
    const logo = ref(store.state.logo);
    // __________________________________



    return {
      Menu,
      CurrentСountry,
      lang,
      store,
      logo,
      a,
      UseCountry,
      backURL
    };
  },
});
