import { createStore } from "vuex";
// const url = new URL(window.location.href);
const url = new URL("https://plonq.ru/salesmap");

const type = {
  type:
    url.hostname == "plonq.ru" || url.hostname == "plonq.kz"
      ? "Plonq"
      : "Flonq",
};
export default createStore({
  state: {
    urlAddress: "google",
    country:
      url.hostname == "plonq.ru"
        ? "ru"
        : url.hostname === "plonq.kz"
        ? "kz"
        : url.hostname === "flonq.si"
        ? "si"
        : url.hostname === "flonq.de"
        ? "de"
        : url.hostname === "flonq.co.uk"
        ? "co.uk"
        : "ae",
    type: type.type,
    openMobileMenu: false,
    openMobileMenuLang: false,
    logo: require(`./img/${type.type}/icon-logo.svg`),
    textForAllLanguages: [
      {
        name: "ru",
        arrayOfLanguageTexts: [
          {
            name: "menu__link",
            array: [],
            text: "Продукты",
          },
          {
            name: "menu__sub",
            array: [
              {
                textarray: ["Plonq Alpha", "Одноразовая электронная сигарета (600 затяжек)"],
                link: "../plonqalpha",
              },
              {
                textarray: ["Plonq Plus", "Одноразовая электронная сигарета"],
                link: "../plonqplus",
              },
              {
                textarray: ["Plonq Plus Pro", "Перезаряжаемая электронная сигарета (4000 затяжек)"],
                link: "../plonqpluspro",
              },
              {
                textarray: ["Plonq Max", "Перезаряжаемая электронная сигарета (6000 затяжек)"],
                link: "../plonqmax",
              },
              {
                textarray: ["Plonq X", "Умная электронная сигарета"],
                link: "../plonqx",
              },
              {
                textarray: ["Instabar by Plonq", "Перезаряжаемая электронная сигарета (5000 затяжек)"],
                link: "../instabarbyplonq",
              }
            ],
            text: "null",
          },
          {
            name: "menu__li",
            array: [
              {
                textarray: ["Дистрибуция"],
                link: "../wholesale",
              },
              {
                textarray: ["Где купить"],
                link: "../salesmap",
              },
            ],
            text: "null",
          },
          {
            name: "mobile-menu__menu-head1",
            array: [
              {
                textarray: ["Plonq Alpha"],
                link: "../plonqalpha",
              },
              {
                textarray: ["Plonq Plus"],
                link: "../plonqplus",
              },
              {
                textarray: ["Plonq Plus Pro"],
                link: "../plonqpluspro",
              },
              {
                textarray: ["Plonq Max"],
                link: "../plonqmax",
              },
              {
                textarray: ["Plonq X"],
                link: "../plonqx",
              },
              {
                textarray: ["Instabar by Plonq"],
                link: "../instabarbyplonq",
              }
            ],
            text: "Продукты",
          },
          {
            name: "mobile-menu__menu-head2",
            array: [
              {
                textarray: ["О приложении"],
                link: "../aboutapp",
              },
              {
                textarray: ["Об электронных сигаретах"],
                link: "../device",
              },
            ],
            text: "Технологии",
          },
          {
            name: "mobile-menu__menu-head3",
            array: [
              {
                textarray: ["Связаться с нами"],
                link: "../feedback",
              },
              {
                textarray: ["Условия возврата"],
                link: "../return",
              },
              {
                textarray: ["Политика конфиденциальности"],
                link: "../privacy",
              },
            ],
            text: "Поддержка",
          },
          {
            name: "mobile-menu__menu-head4",
            array: [
              {
                textarray: ["Точки продаж"],
                link: "../salesmap",
              },
              {
                textarray: ["О Компании"],
                link: "../about-company",
              },
              {
                textarray: ["Оптовым покупателям"],
                link: "../wholesale",
              },
              {
                textarray: ["Экопроект"],
                link: "../eco",
              },
            ],
            text: "Прочее",
          },
        ],
      },
      {
        name: "en",
        arrayOfLanguageTexts: [
          {
            name: "menu__link",
            array: [],
            text: "Products",
          },
          {
            name: "menu__sub",
            array: [
              {
                textarray: ["Flonq Alpha", "A disposable e-cigarette"],
                link: "../flonqalpha.html",
              },
              {
                textarray: ["Flonq X", "A smart vaping device"],
                link: "../flonqx.html",
              },
            ],
            text: "null",
          },
          {
            name: "menu__li",
            array: [
              {
                textarray: ["App"],
                link: "../app",
              },
              {
                textarray: ["Distribution"],
                link: "../sales",
              },
              {
                textarray: ["Stores"],
                link: "../salesmap",
              },
            ],
            text: "null",
          },
          {
            name: "mobile-menu__menu-head1",
            array: [
              {
                textarray: ["Flonq Alpha"],
                link: "../flonqalpha.html",
              },
              {
                textarray: ["Flonq X"],
                link: "../flonqx.html",
              },
            ],
            text: "Products",
          },
          {
            name: "mobile-menu__menu-head2",
            array: [
              {
                textarray: ["About the App"],
                link: "../app",
              },
              {
                textarray: ["About e-cigarettes"],
                link: "../device",
              },
            ],
            text: "Technologies",
          },
          {
            name: "mobile-menu__menu-head3",
            array: [
              {
                textarray: ["Contact us"],
                link: "../feedback",
              },
            ],
            text: "Customer service",
          },
          {
            name: "mobile-menu__menu-head4",
            array: [
              {
                textarray: ["Stores"],
                link: "../salesmap",
              },
              {
                textarray: ["For distributors"],
                link: "../sales",
              },
            ],
            text: "Other",
          },
        ],
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
