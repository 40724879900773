
import { defineComponent } from "vue";

import preloader from "@/components/preloader/PreLoaderComponents.vue";
import Header from "@/components/header/HeaderComponents.vue";
import Map from "@/components/GoogleMap/MapComponents.vue";
import Popup from "@/components/popupNotice/PopupNoticeComponents.vue";
import Search from "@/components/SearchFilter/SearchFilterComponents.vue";
import FilterMap from "@/components/FilterMap/FilterMapComponents.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "GoogleViews",
  components: {
    preloader,
    Header,
    Map,
    Popup,
    Search,
    FilterMap,
  },
  setup() {
    console.log(1);
    const store = useStore()
    return{
      store
    }
  }
});
