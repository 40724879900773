import axios from "axios";
import { createStore } from "vuex";
import { ListSortId, TypeFilter } from '@/ts/types/filter'
import type { IFilter } from "@/ts/types/filter";
const url = new URL("https://store.plonq.ru/");
const countryList = [
  {
    name: "Россия",
    code: "ru",
    coords: "55.755448,37.617185",
  },
  {
    name: "Азербайджан",
    code: "az",
    coords: "40.409262,49.867092"
  },
  {
    name: "Армения",
    code: "am",
    coords: "40.199177,44.512525"
  },
  {
    name: "Узбекистан",
    code: "uz",
    coords: "41.311158,69.279737"
  },
]
const isPlonq = url.hostname.includes('plonq');
const countryData = countryList.find((item) => url.hostname.includes(`plonq.${item.code}`)) || countryList[0];

const type = {
  urlAddress: isPlonq ? "yandex" : "google",
  country: isPlonq ? "ru" : "en",
  type: isPlonq ? "Plonq" : "Flonq",
  lang: isPlonq ? "Russia" : "other",
  filtersCountry: countryData.name,
  Zoom: 9,
  CenterMap: countryData.coords,
};



let mapType = 'normal'
let filter: string[] = [];
if (url.search == "?type=sortSobirator") {
  filter = ["sortSobirator"];
  mapType = 'sobirator'
}
if (url.search == "?type=SuperSmoke") filter = ["SuperSmoke"];
if (url.search == "?type=PlonqX") filter = ["PlonqX"];


function initializeAddresses() {
  return Object.keys(ListSortId).reduce((acc, key) => {
    acc[ListSortId[key as keyof typeof ListSortId]] = [];
    return acc;
  }, {} as Record<string, string[]>);
}


export default createStore({
  state: {
    listFilter: [
      {
        id: ListSortId.data,
        name: "Все точки",
        image: require("@/components/SearchFilter//img/icon-black.svg"),
        pointType: 0,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.top

      },
      {
        id: ListSortId.sortSobirator,
        name: "Собиратор",
        image: require("@/components/SearchFilter/img/icon-grey-pont.svg"),
        pointType: 1,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.top
      },
      {
        id: ListSortId.krasnoe,
        name: "Красное & Белое",
        image: require("@/assets/icon/filters/icon-kb.svg"),
        pointType: 2,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.sortLukoil,
        name: "Лукойл",
        image: require("@/assets/icon/filters/icon-lukoil.svg"),
        pointType: 3,
        width: 33,
        height: 33,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.sortTabCap,
        name: "Табачный капитан",
        image: require("@/assets/icon/filters/icon-tabak.svg"),
        pointType: 4,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.SuperSmoke,
        name: "СуперСмок",
        image: require("@/assets/icon/filters/icon-supersmoke.svg"),
        pointType: 5,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.MosTabak,
        name: "Мостабак",
        image: require("@/assets/icon/filters/icon-mostabak.svg"),
        pointType: 6,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.asbukaVkusa,
        name: "Азбука вкуса",
        image: require("@/assets/icon/filters/icon-azbuka.svg"),
        pointType: 7,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },

      {
        id: ListSortId.sortGazprom,
        name: "Газпром",
        image: require("@/assets/icon/filters/icon-gazprom.svg"),
        pointType: 8,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.sortTatneft,
        name: "Татнефть",
        image: require("@/assets/icon/filters/icon-tatneft.svg"),
        pointType: 9,
        width: 44,
        height: 44,
        active: false,
        position: TypeFilter.bottom
      },
      {
        id: ListSortId.sortLenta,
        name: "Лента",
        image: require("@/assets/icon/filters/icon-lenta.svg"),
        width: 44,
        height: 44,
        active: false,
        pointType: 10,
        position: TypeFilter.bottom
      },


      {
        id: ListSortId.data,
        name: "Очистить",
        pointType: 0,
        width: 44,
        height: 44,
        active: true,
        position: TypeFilter.top
      },
    ] as IFilter[],
    countryList,
    urlAddress: type.urlAddress,
    country: type.country,
    type: type.type,
    lang: type.lang,
    filtersCountry: type.filtersCountry,
    Zoom: type.Zoom,
    CenterMap: type.CenterMap,
    syncStatus: true,
    mapType: mapType,
    addresses: initializeAddresses(),
    filters: filter,
    address_id: null,
    filterSearchStatus: false,
    filterMapTypeStatus: false,
    filterMapStatus: false,
    SearchCord: "",
    GeoCord: "",
    Command: "",
    GeoAddress: "",
    notFoundGeo: "",
  },
  mutations: {
    async addAddresses(state, payload) {
      Object.keys(state.addresses).forEach((key) => {
        state.addresses[key] = payload.data[key] || [];
      });
      console.log(state.addresses);
      return state.addresses;
    },
    async setActualFilter(state) {
      state.listFilter.forEach((item) => {
        const addressItem = state.addresses[item.id as keyof typeof state.addresses].length > 0;
        if (addressItem) {
          item.active = true;
        }
        console.log(item);
      });
      state.listFilter.sort((a, b) => {
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;
        return 0;
      })
    }
  },
  actions: {
    async getAddresses(context, params: string) {
      await axios
        .get(`https://sponq.ru:3100/api/sales-points/`)
        .then(async (res) => {
          await context.commit("addAddresses", res)
          await context.commit("setActualFilter")
        });
    },
  },
  modules: {},
  getters: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    ADDRESSES: (state) => (params1: string[]) => {
      const result = Object.keys(state.addresses).map((key) => {
        return { [key]: state.addresses[key as keyof typeof state.addresses] };
      });
      const pull: string[] = [];
      result.filter((item) => {
        params1.forEach((element) => {
          if (Object.keys(item)[0].includes(element)) {
            item[element].forEach((item) => {
              pull.push(item);
            });
          }
        });
      });
      return pull;
    },
  },
});
