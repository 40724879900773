
import { defineComponent, ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import Gtag from "../assets/gtag";

export default defineComponent({
  name: "NotFoundComponents",
  setup() {
    const store = useStore();
    const lang = ref(store.state.country);
    const NotFoundCount = ref(0);
    const notFound = reactive({
      email: "",
      address: "",
    });
    const getGeoAddress = computed(() => {
      return store.state.GeoAddress;
    });
    const update = () => {
      notFound.address = getGeoAddress.value;
      console.log(notFound.address);
    };
    watch(getGeoAddress, update);
    const notFoundGeo = () => {
      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function success(coords: any) {
        const { latitude, longitude } = coords.coords;
        store.state.notFoundGeo = latitude + "," + longitude;
        return;
      }
      function error() {
        alert("Ваши кординаты не точные");
      }
    };
    const validateEmail = (email: string) => {
      return email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
    const validate = ref(false);
    const validateAddress = ref(false);
    const AddAPerson = async (load: string) => {
      if (load == "telega") {
        let open: any = window.open(`https://t.me/plonq_global`, "_blank");
        new Gtag("Opened a telegram link", "NotFound", "NotFound");
        open.focus();
        return;
      }
      if (notFound.email == null) {
        validate.value = true;
        return;
      }
      if (!validateEmail(notFound?.email)) {
        validate.value = true;
        return;
      }
      if (notFound.address == null || notFound.address == "") {
        validateAddress.value = true;
        return;
      }
      let sendData = {
        email: notFound.email,
        address: notFound.address,
      };
      validate.value = false;
      validateAddress.value = false;
      await axios.post("https://sponq.ru:3100/api/openaddress/AddAPerson", sendData);
      new Gtag("Sent the data NotFound", "NotFound", "NotFound");
      notFound.email = "";
      notFound.address = "";
      NotFoundCount.value = 1;
    };
    return {
      lang,
      store,
      NotFoundCount,
      notFound,
      validateAddress,
      notFoundGeo,
      AddAPerson,
      validate,
      Gtag,
    };
  },
});
