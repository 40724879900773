export enum TypeFilter {
    top = 1,
    bottom = 2
}

export enum ListSortId {
    data = 'data',
    sortSobirator = 'sortSobirator',
    sortLukoil = 'sortLukoil',
    sortTabCap = 'sortTabCap',
    SuperSmoke = 'SuperSmoke',
    MosTabak = 'MosTabak',
    asbukaVkusa = 'asbukaVkusa',
    krasnoe = 'krasnoe',
    sortGazprom = 'sortGazprom',
    sortTatneft = 'sortTatneft',
    sortLenta = 'sortLenta',
}
export interface IFilter {
    id: ListSortId;
    name: string;
    image?: string;
    width: number;
    height: number;
    active: boolean;
    pointType: number;
    position: TypeFilter
}