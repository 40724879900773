import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "map",
    onClick: _cache[0] || (_cache[0] = ($event: any) => {
  _ctx.store.state.filterSearchStatus = false;
_ctx.store.state.filterMapStatus = false;
_ctx.store.state.notFound = false;
}),
    ref: "DivMap"
  }, null, 512))
}