
import { defineComponent, reactive, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
export default defineComponent({
  name: "PopupNoticeComponents",
  setup() {
    const store = useStore();
    const lang = ref(store.state.country);
    const popForm = reactive({
      dislike: false,
      like: false,
      textarea: "",
    });
    const address_id = computed(() => {
      return store.state.address_id;
    });
    const getAddress_id = () => {
      notice.address_id = store.state.address_id._id;
      notice.title = store.state.address_id.label
      if (notice.title == null)
      {
        if (lang.value == "en") {
          notice.title = "pont of sales"
        } else {
          notice.title = "Точка продаж"
          }
        }
      console.log(store.state.address_id);
    };
    watch(address_id, getAddress_id);

    const noticeLoad = () => {
      const data: { id: number; notice: string }[] = [
        {
          id: 0,
          notice: "Stores don’t exist",
        },
      ];
      const dataRu: { id: number; notice: string }[] = [
        {
          id: 0,
          notice: "Магазина не существует",
        },
        {
          id: 1,
          notice: "Неправильный адрес",
        },
      ];
      if (lang.value == "en") return data;
      else return dataRu;
    };
    interface Person {
      title: string;
      address_id: number;
      statusIds: number[];
      userAuthType: any;
      userAuthId: any;
      content: string;
    }
    const notice: Person = reactive({
      address_id: 0, // ID Address
      statusIds: [],
      userAuthType: window.navigator.userAgent,
      userAuthId: window.navigator.language,
      content: "null",
      title: "default"
    });

    const countLike = (pac: string) => {
      if (pac == "like") {
        popForm.like = !popForm.like;
        popForm.dislike = false;
      }
      if (pac == "dislike") {
        popForm.dislike = !popForm.dislike;
        popForm.like = false;
      }
      if (pac == "unsob") {
        popForm.dislike = false;
        popForm.like = false;
        popForm.textarea = "";
        notice.statusIds = [];
      }
    };
    interface ANotice {
      like: boolean;
      dislike: boolean;
      address_id: number | null;
    }
    const addressNotice: ANotice = reactive({
      like: false,
      dislike: false,
      address_id: null,
    });
    const noticeSend = () => {
      // Closing the remarks window
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector(".popup-notice")!.classList.remove("active");
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector("#popupNotice")!.classList.add("active");
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.querySelector("#popupNotice")!.classList.remove("active");
      }, 1500);
      notice.content = popForm.textarea;

      addressNotice.like = popForm.like;
      addressNotice.dislike = popForm.dislike;
      addressNotice.address_id = notice.address_id;
      console.log(notice);

      axios.post(process.env.VUE_APP_SERVER + "/api/openaddress/client_alarm", notice);
      axios.post(
        process.env.VUE_APP_SERVER + "/api/openaddress/couterLike",
        addressNotice
      );
      // Clear
      notice.statusIds = [];
      popForm.textarea = "";
      popForm.like = false;
      popForm.dislike = false;
    };
    const checkNotice = (statusIds: number) => {
      if (!notice.statusIds.includes(statusIds)) notice.statusIds.push(statusIds);
      else {
        notice.statusIds.forEach((item) => {
          if (notice.statusIds[item] == statusIds) notice.statusIds?.splice(item, 1);
        });
      }
    };
    return {
      lang,
      popForm,
      noticeSend,
      notice,
      noticeLoad,
      countLike,
      checkNotice,
    };
  },
});
