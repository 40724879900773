import store from "../../store/index";
const lang = store.state.country;
export default class User {
  label: string;
  address: string;
  content: string;
  sobirator: string;
  constructor(label: string, address: string, type: string) {
    this.label = label;
    this.address = address;
    if (label == "0") label = "Point of Sale";
    this.sobirator =
      type == "true"
        ? '<div class="sobirator"> <p> Пункт сбора использованных электронных сигарет </p> </div>'
        : "";
    this.sobirator =
      type == "1"
        ? '<div class="sobirator"> <p> Пункт сбора использованных электронных сигарет </p> </div>'
        : "";
    if (lang == "ru") {
      this.content = `
    <div id="ballon" class="ballon">
    <div class="ballon_wrapper">
      <div class="title item">${label || "Point of Sale"}</div>
      <div class="close item img right"></div>
      <div class="description item">
      ${address} 
      </div>
      <div id="copyText" class="copy item img right">
        <img
          onclick="
          gtag('event', 'Copy address', {
            'event_category': 'Copy address',
            'event_label': 'Copy address'
            })"
          alt="icon-copy"
          width="18"
          height="18"
          data="Copy address"
          src="${require("@/assets/img/balloon/icon-copy.svg")}"
        />
      </div>
      <div class="footer item" id="btn_route">
        <div class="img item img">
          <img
            onclick="gtag('event', 'Routes', {'event_category': 'Routes
          address','event_label': 'Routes address'})"
            alt="icon-route"
            width="21"
            height="24"
            id="routeImg"
            src="${require("@/assets/img/balloon/icon-routes.svg")}"
          />
        </div>
        <div
          onclick="gtag('event', 'Routes', {'event_category': 'Routes address','event_label': 'Routes address'})"
        >
          Построить маршрут
        </div>
      </div>
      <div class="error img item right">
        <img
          alt="icon-warning"
          width="21"
          height="21"
          onclick='
          document.querySelector(".popup-notice").classList.add("active");
          gtag("event","Open Report",{
          "event_category": "Report address",
          "event_label": "Report address"
          })'
          src="${require("@/assets/img/balloon/icon-notice.svg")}"
        />
      </div>
    </div>
  </div>
        `;
    } else {
      this.content = `
        <div class="point">
        <div class="photo"></div>
        <div class="content">
            <div class="title">${label || "Point of Sale"}</div>
            ${this.sobirator}
            <div class="address">
            <div class="help">Copy address</div>
            ${address} 
            <img alt="icon-copy" onclick="gtag('event', 'Copy address', {'event_category': 'Copy address','event_label': 'Copy address'})" width="18" height="18" data="Copy address" src="${require("@/assets/img/balloon/icon-copy.svg")}">
            <div class="copyText">Address copied</div></div>
            <div class="footer">
                <div class="btn-route">
                    <div class="help">Routes</div>
                    <img onclick="gtag('event', 'Routes', {'event_category': 'Routes address','event_label': 'Routes address'})" alt="icon-route" width="21" height="24" src="${require("@/assets/img/balloon/icon-routes.svg")}">
                    <div onclick="gtag('event', 'Routes', {'event_category': 'Routes address','event_label': 'Routes address'})" class="btn-notice">Routes</div>
                </div>
                <div class="noticeDiv">
                    <p class="help">Report</p>
                    <p id="popupNotice">Successfully sent</p>
                    <img alt="icon-warning" width="21" height="21" onclick='document.querySelector(".popup-notice").classList.add("active"); gtag("event", "Open Report", {"event_category": "Report address","event_label": "Report address"})' src="${require("@/assets/img/balloon/icon-notice.svg")}">
                </div>
            </div>
        </div>
    </div>
        `;
    }
  }
}
