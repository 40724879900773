
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import Gtag from "../assets/gtag";
export default defineComponent({
  setup() {
    const isMobile = window.innerWidth <= 440 ? true : false;
    const store = useStore();
    const lang = ref(store.state.country);
    const Zoom = (zoom: number) => {
      if (zoom > 0) new Gtag("Zoom in button", "BtnGeo", "Zoom");
      else new Gtag("Zoom out button", "BtnGeo", "Zoom");

      zoom = store.state.Zoom + zoom;
      store.state.Zoom = zoom;
    };
    const globalH = ref(window.innerHeight + "px");

    const onFocus = () => {
      setTimeout(() => {
        globalH.value = window.innerHeight + "px";
        document.documentElement.style.setProperty("--globalH", globalH.value);
      }, 200);
    };
    const onBlur = () => {
      setTimeout(() => {
        globalH.value = window.innerHeight + "px";
        document.documentElement.style.setProperty("--globalH", globalH.value);
      }, 200);
    };
    let id = 0;
    const setGeoMap = (notFoundGeo: string) => {
      new Gtag("Geo-location button", "BtnGeo", "Geo");
      if (id >= 1) {
        store.state.GeoCord = `PanTo${id}`;
        id++;
        return;
      }
      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function success(coords: any) {
        if (notFoundGeo == "notFoundGeo") {
          const { latitude, longitude } = coords.coords;
          store.state.notFoundGeo = latitude + "," + longitude;
          return;
        }
        const { latitude, longitude } = coords.coords;
        const cord = latitude + "," + longitude;
        store.state.GeoCord = cord;
        id++;
      }
      function error() {
        alert("Ваши кординаты не точные");
      }
    };
    const filters = reactive({
      country: store.state.filtersCountry,
    });
    const CurrentCountryType = store.state.type;
    const Menu = ref();
    if (CurrentCountryType == "Plonq") {
      Menu.value = store.state.countryList
    }
    const setCountry = (coords: string, name: string) => {
      filters.country = name;
      new Gtag(name, "BtnGeo", "setCountry");

      store.state.CenterMap = coords;
    };

    const NotFoundCount = ref(0);
    const notFound = reactive({
      email: "",
      address: "",
    });
    const getGeoAddress = computed(() => {
      return store.state.GeoAddress;
    });
    const update = () => {
      notFound.address = getGeoAddress.value;
      console.log(notFound.address);
    };
    watch(getGeoAddress, update);

    const notFoundGeo = () => {
      setGeoMap("notFoundGeo");
    };
    const validateEmail = (email: string) => {
      return email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
    const validate = ref(false);
    const validateAddress = ref(false);
    const AddAPerson = async (load: string) => {
      if (load == "telega") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let open: any = window.open(`https://t.me/plonq_global`, "_blank");
        new Gtag("Opened a telegram link", "NotFound", "NotFound");
        open.focus();
        return;
      }
      if (notFound.email == null) {
        validate.value = true;
        return;
      }
      if (!validateEmail(notFound?.email)) {
        validate.value = true;
        return;
      }
      if (notFound.address == null || notFound.address == "") {
        validateAddress.value = true;
        return;
      }
      let sendData = {
        email: notFound.email,
        address: notFound.address,
      };
      validate.value = false;
      validateAddress.value = false;
      await axios.post(
        process.env.VUE_APP_SERVER + `/api/openaddress/AddAPerson`,
        sendData
      );
      const sendAmo = {
        email: notFound.email,
        tags: [
          {
            name: "plonq.ru/salesmap",
          },
          {
            name: "notFound",
          },
        ],
        phone: null,
        name: "salesmap",
        message: notFound.address,
        city: "Нет",
        urUser: "Нет",
        shopAddress: "Нет",
        StoreSign: "Нет",
        StoreFormat: "Нет",
        SalesRepresentative: "Нет",
      };
      await axios.post("https://sponq.ru:3100/api/amocrm/other", sendAmo);
      new Gtag("Sent the data NotFound", "NotFound", "NotFound");
      notFound.email = "";
      notFound.address = "";
      NotFoundCount.value = 1;
    };
    return {
      store,
      Menu,
      lang,
      Zoom,
      setGeoMap,
      filters,
      setCountry,
      Gtag,
      NotFoundCount,
      notFound,
      validateAddress,
      notFoundGeo,
      AddAPerson,
      validate,
      isMobile,
      onFocus,
      onBlur,
      globalH,
    };
  },
});
