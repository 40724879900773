import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Yandex from '../views/YandexViews.vue'
import Google from '../views/GoogleViews.vue'
let useMap
let name
if (store.state.urlAddress == 'yandex') { useMap = Yandex; name = 'Yandex' }
else { useMap = Google; name = 'Google' }

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: name,
    component: useMap
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
