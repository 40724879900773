import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Map = _resolveComponent("Map")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_Search = _resolveComponent("Search")!
  const _component_FilterMap = _resolveComponent("FilterMap")!
  const _component_preloader = _resolveComponent("preloader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_Map),
    _createVNode(_component_Popup),
    _createVNode(_component_Search),
    _createVNode(_component_FilterMap),
    (_ctx.store.state.syncStatus)
      ? (_openBlock(), _createBlock(_component_preloader, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}