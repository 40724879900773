
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var ymaps: any;
import { defineComponent, ref, onBeforeMount, computed, watch } from "vue";
import { useStore } from "vuex";
import User from "../assets/contentBallon";
import Gtag from "../assets/gtag";
export default defineComponent({
  name: "MapComponents",
  setup() {
    const store = useStore();

    const cord = computed(() => {
      return store.state.SearchCord;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let Search: any;
    const createSearchPoint = () => {
      if (cord.value == "delete") {
        if (Search == undefined) return;
        map.geoObjects.remove(Search);
        return;
      }
      let cords = [Number(cord.value.split(",")[0]), Number(cord.value.split(",")[1])];
      if (Search) {
        map.geoObjects.remove(Search);
      }
      Search = new ymaps.Placemark(
        cords,
        {},
        {
          iconLayout: "default#image",
          iconImageHref: require("@/assets/img/points/icon-search.svg"),
          iconImageSize: [35, 48],
        }
      );
      map.geoObjects.add(Search);
      map.setCenter(cords, 15, {
        checkZoomRange: true,
      });
    };
    watch(cord, createSearchPoint);
    const geoCord = computed(() => {
      return store.state.GeoCord;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let GeoPoint: any;
    let cordsGeo: number[];
    const createGeoPoint = () => {
      if (GeoPoint !== undefined) {
        map.setCenter(cordsGeo, 15, {
          checkZoomRange: true,
        });
        return;
      }
      cordsGeo = [
        Number(geoCord.value.split(",")[0]),
        Number(geoCord.value.split(",")[1]),
      ];
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      GeoPoint = new ymaps.Placemark(
        cordsGeo,
        {},
        {
          iconLayout: "default#image",
          iconImageHref: require("@/assets/img/points/I_m_here.svg"),
          iconImageSize: [35, 48],
        }
      );
      GeoPoint.events.add("hover", function () {
        document
          .querySelector(
            "#app > div > div.map > ymaps > ymaps > ymaps > ymaps.ymaps-2-1-79-places-pane > ymaps > ymaps > ymaps"
          )
          ?.classList.add("hoverIamHere");
      });
      GeoPoint.events.add("mouseleave", function () {
        document
          .querySelector(
            "#app > div > div.map > ymaps > ymaps > ymaps > ymaps.ymaps-2-1-79-places-pane > ymaps > ymaps > ymaps"
          )
          ?.classList.remove("hoverIamHere");
      });
      map.geoObjects.add(GeoPoint);
      map.setCenter(cordsGeo, 15, {
        checkZoomRange: true,
      });
    };
    watch(geoCord, createGeoPoint);

    const Zoom = computed(() => {
      return store.state.Zoom;
    });
    const setZoom = () => {
      map.setZoom(Zoom.value, {
        duration: 300,
        timingFunction: "ease-in",
      });
    };
    watch(Zoom, setZoom);

    const MapCenter = computed(() => {
      return store.state.CenterMap;
    });

    const setCenter = () => {
      let cord = [
        Number(MapCenter.value.split(",")[0]),
        Number(MapCenter.value.split(",")[1]),
      ];
      map.setCenter(cord, 7, {
        checkZoomRange: true,
      });
    };
    watch(MapCenter, setCenter);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let sortedAddress: any;
    const address = (type: string[]) => {
      let get = store.getters.ADDRESSES(type);
      if (get.length == 0) {
        setTimeout(() => {
          address(type);
        }, 500);
      } else {
        sortedAddress = get;
        createPoints();
      }
    };
    const filters = computed(() => {
      return store.state.filters;
    });
    watch(filters.value, async (item) => {
      let filter: string[] = [];
      if (item[0] == "data" || item.length == 0) {
        store.state.filters.splice(0, store.state.filters.length);
        address(["data"]);
        return;
      }
      await item.forEach((item: string) => filter.push(item));
      if (store.state.filters.length > 0) address(filter);
    });
    const lang = store.state.country;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let objectManager: any;

    const createPoints = async () => {
      if (sortedAddress.length == 0) return;
      if (objectManager) objectManager.removeAll();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let placeMark = sortedAddress.map((item: any) => {

        let image
        image = require(`./icons/icon_point-${item.pointType}.svg`);
        let content;
        let hintContent;
        if (lang == "ru") {
          content = new User(item.label, item.address, "normal").content;
          hintContent = item.address;
        }
        if (item.pointType == 1) {
          content = new User(item.label, item.address, "1").content;
          hintContent = "Собиратор";
        }
        let imageSize = [];
        if (window.screen.width < 940) imageSize = [30, 39];
        else imageSize = [35, 47];
        let markerObjectManager = {
          id: item._id,
          infoMarker: item,
          hideIconOnBalloonOpen: false,
          zIndex: hintContent === "Собиратор" ? 1000 : 0,
          geometry: item.location,
          properties: {
            hintContent: hintContent,
            balloonContent: content,
            hideIconOnBalloonOpen: false,
          },
          options: {
            iconLayout: "default#image",
            iconImageHref: image,
            iconImageSize: imageSize,
            hideIconOnBalloonOpen: false,
          },
        };
        return markerObjectManager;
      });
      await objectManager.add(placeMark);
      store.state.syncStatus = false;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let map: any;
    const divYandex = ref();
    const zoom = store.state.Zoom;
    const center = [
      Number(store.state.CenterMap.split(",")[0]),
      Number(store.state.CenterMap.split(",")[1]),
    ];

    const init = async () => {
      map = await new ymaps.Map(
        divYandex.value,
        {
          center: center,
          zoom: zoom,
        },
        {
          minZoom: 4,
        }
      );
      const customClusterIconLayout = ymaps.templateLayoutFactory.createClass(
        '<span class="balloon__icon">$[properties.geoObjects.length]</span>'
      );
      let sizeIcon = [67, 67];
      const clusterIcons = [
        {
          href: require("./img/Group-normal.svg"),
          size: sizeIcon,
          offset: [-50, -50],
        },
      ];
      const clusterIconsEco = [
        {
          href: require("./img/Group-eco.svg"),
          size: sizeIcon,
          offset: [-45, -45],
        },
      ];
      objectManager = await new ymaps.ObjectManager({
        clusterize: true,
        clusterOpenBalloonOnClick: false,
        gridSize: 95,
        maxZoom: 14,
      });
      await objectManager.clusters.options.set({
        clusterIcons: clusterIcons,
        clusterIconContentLayout: customClusterIconLayout,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await objectManager.clusters.events.add("add", function (e: any) {
        let cluster = objectManager.clusters.getById(e.get("objectId"));
        let objects = cluster.properties.geoObjects;
        let sobiratorCluster = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        objects.forEach((item: any) => {
          if (item.infoMarker.pointType == 1)
            sobiratorCluster = true;
        });
        if (sobiratorCluster) {
          objectManager.clusters.setClusterOptions(cluster.id, {
            clusterIcons: clusterIconsEco,
            clusterIconContentLayout: customClusterIconLayout,
          });
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await objectManager.events.add("balloonopen", async (event: any) => {
        let zoomMap = map.getZoom();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let markers: any;
        if (zoomMap < 13) {
          markers = document.querySelectorAll(".ymaps-2-1-79-image");
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          markers.forEach((item: any) => {
            item.classList.add("opacity");
          });
        }
        let a = event.get("objectId");
        let content =
          event.originalEvent.currentTarget.objects._objectsById[a].infoMarker;
        let cord = content.location.coordinates
        store.state.address_id = content;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const abs = document.querySelector(".ymaps-2-1-79-balloon_layout_normal")!;
        let top = getComputedStyle(abs, "top").top;
        let tops: string[] = top.split("px");
        abs.setAttribute("style", `opacity: 0;`);
        new Gtag(content.code, "OpenPoint", "Open");

        setTimeout(async () => {
          if (zoomMap < 13) {
            abs.setAttribute("style", `opacity: 0;`);
          }
          await map.panTo(
            [
              [cord[0], cord[1]],
              [cord[0], cord[1]],
            ],
            {
              flying: 1,
            }
          );
        }, 100);
        setTimeout(async () => {
          if (zoomMap < 13) {
            await map.setZoom(15, {
              duration: 600,
              timingFunction: "ease-in-out",
              checkZoomRange: true,
              useMapMargin: true,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            markers.forEach((item: any) => {
              item.classList.remove("opacity");
            });
            abs.setAttribute("style", `top: ${Number(tops[0]) - 52}px; opacity: 1;`);
          }
        }, 1000);
        if (zoomMap >= 13) {
          setTimeout(async () => {
            abs.setAttribute("style", `top: ${Number(tops[0]) - 52}px; opacity: 1;`);
          }, 200);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const btn_route = document.querySelector("#btn_route")!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const copyText = document.querySelector("#copyText")!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const route = document.querySelector("#routeImg")!;
        const ballon = document.getElementById("ballon")!;
        route.addEventListener("mouseover", () => {
          route.classList.add("active");
        });
        route.addEventListener("mouseleave", () => {
          route.classList.remove("active");
        });
        copyText.addEventListener("mouseover", () => {
          copyText.classList.add("active");
        });
        copyText.addEventListener("mouseleave", () => {
          copyText.classList.remove("active");
        });
        copyText.addEventListener("click", () => {
          CopyTextMarker(content.address);
          copyText.classList.remove("active");
          ballon.classList.add("active");
          const timeClose = () => ballon.classList.remove("active");

          setTimeout(() => {
            timeClose();
          }, 5000);
        });
        btn_route.addEventListener("click", () => {
          setLink(content.location.coordinates);
        });
      });
      const setLink = (cord: number[]) => {
        let baseCord = cord
        let geoCoords = baseCord[0] + "," + baseCord[1];

        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          let link = `maps://?q=' + ${cord}`;
          window.open(link, "_system");
        } else if (
          /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ) {
          window.location.href = `geo:${geoCoords}?daddr=${geoCoords}`;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let open: any = window.open(`https://yandex.ru/maps/?rtext=~${cord}`, "_blank");
          open.focus();
        }
      };
      const CopyTextMarker = (text: string) => {
        navigator.clipboard.writeText(text);
      };
      map.behaviors.enable("scrollZoom");
      map.controls.remove("geolocationControl");
      map.options.set("maxAnimationZoomDifference", Infinity);
      map.controls.remove("searchControl");
      map.controls.remove("trafficControl");
      map.controls.remove("typeSelector");
      map.controls.remove("fullscreenControl");
      map.controls.remove("zoomControl");
      map.controls.remove("rulerControl");
      map.geoObjects.add(objectManager);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.events.add("boundschange", function (e: any) {
        if (e.get("newZoom") !== e.get("oldZoom")) {
          store.state.Zoom = map._zoom;
        }
      });
    };
    window.addEventListener("resize", () => {
      setTimeout(() => {
        map.container.fitToViewport();
      }, 1000);
    });
    onBeforeMount(async () => {
      await ymaps.ready(init);
      setTimeout(async () => {
        if (store.state.filters[0] == "sortSobirator") await address(["sortSobirator"]);
        else if (store.state.filters[0] == "SuperSmoke") await address(["SuperSmoke"]);
        else if (store.state.filters[0] == "PlonqX") await address(["PlonqX"]);
        else if (store.state.filters.length == 0) await address(["data"]);
        else await address(["data"]);
      }, 500);
    });

    return {
      divYandex,
      store,
    };
  },
});
